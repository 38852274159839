import React from "react";
import img from '../images/posterimg.jpeg';
import './hero.css'

const HeroSection = () => {


  const whatsappNumber = "917081111066";

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
    window.location.href = whatsappLink;
  };
  return (
    <div style={styles.heroContainer}>
      <img
        onClick={redirectToWhatsApp}
        src={img} // Replace with the path to your poster image
        alt="Hero Image"
        style={styles.heroImage}
      />
    </div>
  );
};

const styles = {
  heroContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  heroImage: {
    width: "100%",
    height: "auto",
    display: "block",
  },
};

export default HeroSection;
