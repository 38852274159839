import React from 'react';
import mn1 from '../images/pic1.jpeg';
import mn2 from '../images/kohli.jpeg';
import mn3 from '../images/casino1.jpeg';
import mn4 from '../images/pic4.jpeg';
import mn5 from '../images/pic5.jpeg';
import mn6 from '../images/pic6.jpeg';
import mn7 from '../images/pic7.jpeg';
import mn8 from '../images/pic8.jpeg';

import './ImageGrid.css';

const ImageGrid = () => {
  // Dummy image data, replace with actual image sources and corresponding WhatsApp numbers
  const images = [
    { src: mn1, whatsappNumber: '917800111033' },
    { src: mn2, whatsappNumber: '917081111066' },
    { src: mn3, whatsappNumber: '917081111066' },
    { src: mn4, whatsappNumber: '917800111113' },
    { src: mn5, whatsappNumber: '917800111112' },
    { src: mn6, whatsappNumber: '917800111044' },
    { src: mn7, whatsappNumber: '917800111022' },
    { src: mn8, whatsappNumber: '91755909954' },
     
  ];

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.location.href = whatsappLink;
  };

  return (
    <div className="gridContainer">
      {images.map((image, index) => (
        <div key={index} className="gridItem" onClick={() => redirectToWhatsApp(image.whatsappNumber)}>
          <img src={image.src} alt={`Image ${index + 1}`} className="imageC" />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
