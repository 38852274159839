import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import MainSection from './components/MainSection';
import ImageGrid from './components/ImageGrid';
import UpiSection from './components/UpiSection';
import FooterComponent from './components/FooterComponent';
import YourComponent from './components/YourComponent';
import InfoComponent from './components/InfoComponent';
import PersonComponent from './components/PersonComponent';
import FeatureComponent from './components/FeatureComponent';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <HeroSection/>
      <MainSection/>
      <ImageGrid/>
      <FeatureComponent/>
      <UpiSection/>
      <PersonComponent/>

      {/* <CardComponent/> */}
     
      {/* <YourComponent/> */}
      <FooterComponent/>
      
    </div>
  );
}

export default App;
