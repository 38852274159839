// PersonComponent.js

import React, { useRef, useEffect , useState } from "react";
import InfoComponent from "./InfoComponent";
import "./PersonComponent.css";

const data = [
  { name: "Kartik", description: "Pehele toh main local book pe khelta tha or uski settling Monday hoti thi. Par Jabse lotus online par khelna sheru kara hai, Settling within 10minutes ho Jasti hai and withdrawal bhi 10 mins. Mein mil janta hai. Highly recommending lotus online to all" },
  { name: "Vijay", description: "Instant withdrawal - within 5 mins , Customer support Poori raat bhar bhi or Solution within 10 minutes. Or kyaa chahiye, Lotus the most trusted platform" },
  { name: "Rahul", description: "Casino mein Jeeta 10 Hazaar, Khela Lotus par baar baar. Kyuki Lotus hi hai sabse Reliable Trusted Aur Accurate. Main Jeet raha hu, Poora India jeet raha hai Lotus par" },
  { name: "Sonu", description: "Lotus Online toh trusted platform hai hi or aapko apne paise ki bilkul fikar nahi honi chahiye. Fikar honi chahiye responsibly khelne ki or control mein khelne ki. Jeeto or yahan kabhi aapki jeet rukegi nahi. " },
  ,
];


// Update the following JavaScript in PersonComponent.js

// ... existing code ...

const PersonComponent = () => {
  const gridRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const options = {
      root: gridRef.current,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          document.querySelectorAll(".grid-item").forEach((item) => {
            item.classList.remove("active");
          });

          entry.target.classList.add("active");
        }
      });
    }, options);

    document.querySelectorAll(".grid-item").forEach((item) => {
      observer.observe(item);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the active index
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 1000); // Change the interval as needed (1 second in this example)

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  return (
    <div>
      <div className="boxer">
        <h1 className="tex">Trusted By Millions</h1>
        <div ref={gridRef} className="grid-container">
          {data.map((item, index) => (
            <div
              key={index}
              className={`grid-item ${index === activeIndex ? "active" : ""}`}
            >
              <img
                className="imgsty"
                src={require("../images/1.png")}
                alt={`Person ${index + 1}`}
              />
              <div className="content">
                <div className="name">{item.name}</div>
                <div className="description">{item.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <InfoComponent />
    </div>
  );
};

export default PersonComponent;