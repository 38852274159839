// InfoComponent.js

import React from "react";
import "./info.css";

const InfoComponent = () => {
  return (
    <div className="rectangle-container">
      <div className="rectangle">
        <p className="text">
          For More Information and Recent Payment Proofs Join Our Telegram
          Channel.
        </p>

        <a
          href="https://t.me/thelotusbookonline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="join">JOIN NOW</button>
        </a>
      </div>
    </div>
  );
};

export default InfoComponent;
