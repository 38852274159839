import React, { useState,useEffect } from "react";
import "./Navbar.css"; 
import logo from '../images/logo.png';
import logo2 from '../images/Rectangle3.png';

const Navbar = () => {

  const whatsappNumber = "917081111066";

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
    window.location.href = whatsappLink;
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const[screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update screenWidth when the component mounts
    console.log("tttt",screenWidth);
    setScreenWidth(window.innerWidth);

    // Add event listener to update screenWidth on resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  

  return (
    <nav className="navbar">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="logo">
          <img src={logo} />
        </div>
        <div class="logo2">
          <img src={logo2} />
        </div>
      </div>

      <div className="menu">
        {screenWidth < 768 ? (
          <button className="btnstyle1" onClick={redirectToWhatsApp}>
            Play
          </button>
        ) : (
          <button className="btnstyle1" onClick={redirectToWhatsApp}>
            How To Play
          </button>
        )}

        <button className="btnstyle2" onClick={redirectToWhatsApp}>
          Sign Up
        </button>
        <button className="btnstyle3" onClick={redirectToWhatsApp}>
          Login
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
