// FeatureComponent.js

import React from "react";
import "./FeatureComponent.css";

import image1 from "../images/ic111.png";
import image2 from "../images/ic2.png";
import image3 from "../images/ic3.png";
import image4 from "../images/ic4.png";
import image5 from "../images/ic5.png";

const data = [
  {
    imageUrl: image1,
    description:
      "security is our top priority and hence your data and information is safe and not shared at any point",
    heading: "High Security",
  },
  {
    imageUrl: image2,
    description:
      "we  have a team of  experts who are available to guide you 24*7 through our whatsapp chats",
    heading: "24/7 Support",
  },
  {
    imageUrl: image3,
    description:
      "multiple methods are available for payment. Customers can pay via Net banking , Banking transfer , Google Pay , paytm , IMPS , PhonePe etc",
    heading: "Available Payment Method",
  },
  {
    imageUrl: image4,
    description:
      "transparency and accuracy is most important for us and for our customers. we deal in most transparent way which makes us reliable",
    heading: "100 % Trusted and Reliable",
  },
  {
    imageUrl: image5,
    description:
      "we provide instant service of account withdrawal & refill with minimum to maximum amount",
    heading: "Instant Withdrawal and Refill",
  },
];

const FeatureComponent = () => {
  return (
    <div className="texte">
      <h1>WHY? THE LOTUS ONLINE</h1>
      <div className="gr-container">
        {data.map((item, index) => (
          <div  className="min1">
            <div key={index} className="gr-item">
              <img
                src={item.imageUrl}
                alt={`Image ${index + 1}`}
                className="image"
              />
            </div>
            <p  className="pptag1" >
              {item?.heading}
            </p>
            <p className="pptag2">
              {item?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureComponent;
