// FooterComponent.jsx

import React from 'react';
import './Footer.css'; // Import your CSS file
import insta from '../images/Instagram.png';

import whats from '../images/WhatsApp.png';
import YourComponent from './YourComponent';
const Footer = () => {

  const whatsappNumber = "917081111066";

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
    window.location.href = whatsappLink;
  };
  return (
    <footer className="footer">
      <div className='yoursyle'><YourComponent/></div>
      <div className="social-icons">
       <a href="https://instagram.com/thelotusonline.in" target="_blank" rel="noopener noreferrer">
          <img src={insta} alt="Instagram" />
        </a>
        
         
          <img onClick={redirectToWhatsApp} src={whats} alt="WhatsApp" />
      </div>
      <div className="disclaimer">
        <p className='disstyle'>Disclaimer</p>
        <p>This Website is only for 18+ users. If you are from Telangana, Orissa, Assam, Sikkim, and Nagaland Please leave the website immediately. Be aware of fraudsters,
 we will get in touch with you within 24 hrs.</p>
      </div>
    </footer>
  );
};

export default Footer;
