import React from 'react'
import box from '../images/Rectangle11.png';
import './upisection.css';
import upi from '../images/upi.png'
import astro from '../images/astropay.png'
import bank from '../images/bank.png'
import mobi from '../images/mobi.png'
import paytm from '../images/paytm.png'

function UpiSection() {
  return (
    <div className='head-text'>
        
        <div className='text-on-image'>
             <h2 className="text1">WITHDRAW WINNINGS IN ABOUT 100 SECONDS</h2>
        </div>


    <div className="image-container">
      <div className="image-wrapper">
        <img src={upi} alt="Image 1" />
      </div>
      <div className="image-wrapper">
        <img src={astro} alt="Image 2" />
      </div>
      <div className="image-wrapper">
        <img src={bank} alt="Image 3" />
      </div>
      <div className="image-wrapper">
        <img src={mobi} alt="Image 4" />
      </div>
      <div className="image-wrapper">
        <img src={paytm} alt="Image 5" />
      </div>
    </div>

    </div>
  )
}

export default UpiSection