import { Button } from 'bootstrap';
import React from 'react'
import './mainsection.css'
import  star from '../images/Star.png';
import box1 from '../images/Rectangle9.png';
import ppp from '../images/pseudooo1.png';
import p1 from '../images/pseudo1.png';

function MainSection() {

  const whatsappNumber = '917081111066';

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
    window.location.href = whatsappLink;
  }
  return (
    <div>
      <div className="main1">
        <div className="main10">
          <div className="main2">
            <h1 className="text-center">
              India‘s best licensed and legal company
            </h1>
            <div>
              <p class="sub">
                The lotus online is more than just an online betting platform.
                We boast a 'focus on the player' approach and have built our
                reputation on offering clients nothing but the best, from
                cutting-edge technology. Our live betting feature allows you to
                bet on your favorites quickly and easily, and in real time.
              </p>
            </div>
          </div>
          <div className="btnmain">
            <button onClick={redirectToWhatsApp} class="btnn">24/7 Service</button>
          </div>
        </div>
        <div className="main3">
          <div className="starDiv">
            <img src={star} />
            <h5>24 hours withdrawal</h5>
          </div>
          <div className="starDiv">
            <img src={star} />
            <h5>Minimum account Rs. 100</h5>
          </div>
          <div className="starDiv">
            <img src={star} />
            <h5>200+ types of casino</h5>
          </div>
          <div className="starDiv">
            <img src={star} />
            <h5>Special customer care number</h5>
          </div>
          <div className="starDiv">
            <img src={star} />
            <h5>No Document</h5>
          </div>
          <div className="starDiv">
            <img src={star} />
            <h5>No Registration</h5>
          </div>
        </div>
      </div>

      <div class="image-cont">
        <div className="image-wrap">
          <img  className='img7' src={ppp} alt="Image 1" />
          <div className="main4">
            <h1 className="header1">10 Lakh +</h1>
            <p className="ptag1">Users</p>
          </div>
        </div>
        <div className="image-wrap">
          <img className='img7' src={p1} alt="Image 1" />
          <div className="main4">
            <h1 className="header1">₹1000 Crores +</h1>
            <p className="ptag1">Winnings Distribution</p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default MainSection;