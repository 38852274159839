// YourComponent.js

import React from "react";
import "./yourcomponent.css";

const YourComponent = () => {
  const whatsappNumber = "917081111066";

  // Function to redirect to WhatsApp
  const redirectToWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="left-content">
        <h2 className="headersty">Self withdraw & Self deposit</h2>
      </div>
      <div className="right-content">
        {/* Attach the redirectToWhatsApp function to the onClick event of the button */}
        <button onClick={redirectToWhatsApp}>Click me</button>
      </div>
    </div>
  );
};

export default YourComponent;
